import {createContext, useState} from "react";
import {Project} from "../models/Project";
import {useSessionManager} from "./UseSessionManager";


export const DefaultProjectContext = createContext<{project?: Project, defaultProject?: Project, setDefaultProject?: (project: Project)=> void, setProject?: (project?: Project)=> void, activeBrains: string[], setActiveBrains?: (brainId: string[]) => void}>({activeBrains: []});

export const DefaultProjectProvider = ({children}: {children: any})=> {
    const {defaultProject, setDefaultProject} = useSessionManager();
    const [project, selectProject] = useState<Project|undefined>(defaultProject);
    const [activeBrains, setActiveBrains] = useState<string[]>((defaultProject && defaultProject.brainId)?[defaultProject?.brainId]:[]);

    const putProject = (project?: Project, isDefault?: boolean) => {
        selectProject(project)
        if (project && isDefault) {
            setDefaultProject(project)
        }
    }

    return <DefaultProjectContext.Provider value={{project: project, activeBrains: activeBrains, defaultProject: defaultProject, setDefaultProject: (project)=> putProject(project, true), setProject: (project)=> putProject(project, false), setActiveBrains: setActiveBrains}}>
        {children}
    </DefaultProjectContext.Provider>
}