import { ApiContract } from "../network/dto/ApiContract";
import { RegistrationModel } from "../models/RegistrationModel"; 

// 🔹 Define API contract for registration
const registerUserApiContract = (payload: RegistrationModel): ApiContract => {
    return {
        url: "api/public/registration",
        //url: "http://localhost:8081/api/public/registration",
        method: "POST",
        requestBody: payload
    };
};

export { registerUserApiContract };
