import {Button, Form, Spinner, Tab, Tabs} from "react-bootstrap";
import {useEffect, useState} from "react";
import {ReportRequest, ReportResponse} from "../../../models/Report";
import {createReport, getUserReport} from "../../../repo/ReportApi";
import {AllToasts} from "../../../components/toast/AllToasts";
import "./SirReportPage.scss";
import {useSessionManager} from "../../../hooks/UseSessionManager";
import {Link} from "react-router-dom";

const SirReportPage = () => {

    // const [activeTab, setActiveTab] = useState<string>("generate");
    // const [report, setReport] = useState<ReportResponse | undefined>(undefined);
    const {session} = useSessionManager();
    const [payload, setPayload] = useState<ReportRequest>({
        title: "",
        content: "",
        share_emails: [],
        type: "sheet",
        user_id: Number(session?.user?.id)
    });
    // const [documentId, setDocumentId] = useState<string>("");
    // const [documentUrl, setDocumentUrl] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [emailPayload, setEmailPayload] = useState<string>("");
    const [error, setError] = useState<string | undefined>(undefined);
    const emailRegex = /^\s*([\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,})(\s*,\s*[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,})*\s*$/;
    const [sirReportId, setSirReportId] = useState<string | undefined>(undefined);
    const [shrinking, setShrinking] = useState<boolean>(false);
    const [userReportLoading, setUserReportLoading] = useState<boolean>(true);


    useEffect(() => {
        if (session) {
            setPayload(prevState => ({...prevState, user_id: Number(session?.user?.id)}))
            handleGetUserReport(Number(session?.user?.id));
        }
    }, [session]);

    const handleCreateReport = async () => {
        setLoading(true);
        try {
            if (!emailRegex.test(emailPayload)) {
                setError("Please provide comma seperated list of emails");
                return;
            }
            const response = await createReport({
                ...payload,
                share_emails: emailPayload.split(",").map(email => email.trim()).filter(email => email)
            });
            // setReport(response);
            setSirReportId(response.document_id);
        } catch (error) {
            console.error("Failed to create report:", error);
            AllToasts.failure("failed to create document");
        } finally {
            setLoading(false);
        }
    };

    const handleGetUserReport = async (doddaUserId: number) => {
        setUserReportLoading(true);
        try {
            const response = await getUserReport(doddaUserId);
            if (response.exists) {
                setSirReportId(response.doc_id);
            }
        } catch (error) {
            console.error("Failed to fetch user report:", error);
        } finally {
            setUserReportLoading(false);
        }
    }

    // const handleFetchReport = (documentId: string) => {
    //     if (payload.type === "sheet") {
    //         setDocumentUrl(`https://docs.google.com/spreadsheets/d/${documentId}/edit`)
    //     } else {
    //         setDocumentUrl(`https://docs.google.com/document/d/${documentId}/edit`)
    //     }
    // }

    // const resetState = () => {
    //     setReport(undefined);
    //     setPayload({title: "", content: "", share_emails: [], type: "sheet", user_id: 0});
    //     setDocumentId("");
    //     setDocumentUrl(undefined);
    //     setEmailPayload("");
    //     setError(undefined);
    //     setLoading(false);
    // }

    // const handleCopyToClipboard = () => {
    //     if (documentUrl) {
    //         navigator.clipboard.writeText(documentUrl)
    //             .then(() => {
    //                 console.log("Copied to clipboard!");
    //             })
    //             .catch((err) => {
    //                 console.error("Failed to copy:", err);
    //             });
    //     } else if (report?.document_url) {
    //         navigator.clipboard.writeText(report.document_url)
    //             .then(() => {
    //                 console.log("Copied to clipboard!");
    //             })
    //             .catch((err) => {
    //                 console.error("Failed to copy:", err);
    //             });
    //     }
    // };

    // const handleCopyToClipboard = () => {
    //     if (report?.document_id) {
    //         navigator.clipboard.writeText(report.document_id)
    //             .then(() => {
    //                 console.log("Copied to clipboard!");
    //             })
    //             .catch((err) => {
    //                 console.error("Failed to copy:", err);
    //             });
    //     }
    // }

    const handleCopyClick = () => {
        if (sirReportId) {
            setShrinking(true);
            navigator.clipboard.writeText(sirReportId);
            setTimeout(() => setShrinking(false), 100);
        }
    };


    return (
        userReportLoading ?
            <div className={"w-100 d-flex justify-content-center align-items-center py-5"}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                </Spinner>
            </div> :
            <div className={"d-flex flex-column align-items-center p-5"}>
                <div className={"shadow-lg p-4"} style={{width: "550px"}}>
                    {
                        sirReportId ?
                            <>
                                <h6>
                                    Here is your document ID
                                </h6>
                                <div className={"d-flex justify-content-between p-3 pb-2"} style={{background: "#eff1f1"}}>
                                    <p>
                                        {sirReportId}
                                    </p>
                                    <i
                                        className={`bi bi-copy ${shrinking ? "shrink" : ""}`}
                                        onClick={handleCopyClick}
                                        style={{cursor: "pointer", transition: "transform 0.1s ease"}}
                                    ></i>
                                </div>
                                <h6 className={"mt-4"}>
                                    Copy the above ID and paste it inside the link below
                                </h6>
                                <div className={"p-3"} style={{background: "#eff1f1"}}>
                                    <a href="https://68e5-34-123-15-205.ngrok-free.app/" target="_blank"
                                       rel="noopener noreferrer">
                                        https://68e5-34-123-15-205.ngrok-free.app/
                                    </a>
                                </div>
                            </> :
                            <>
                                <h5 className={"text-center"}>
                                    Create Document ID
                                </h5>
                                <Form onSubmit={(event) => {
                                    event.preventDefault();
                                    handleCreateReport();
                                }} className={"mt-3"}>
                                    <div>
                                        <Form.Group>
                                            <Form.Label>Report type <span
                                                className={"text-muted"}><small>(Google doc or spreadsheet)</small></span></Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="mb-3"
                                                value={payload.type}
                                                onChange={(event) =>
                                                    setPayload(prevState => ({
                                                        ...prevState,
                                                        type: event.target.value as "sheet" | "docs"
                                                    }))
                                                }
                                            >
                                                <option value="sheet">Spreadsheet</option>
                                                <option value="docs">Document</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicTitle">
                                            <Form.Label>Title <span
                                                className={"text-muted"}><small>(required)</small></span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name={"title"}
                                                value={payload.title}
                                                placeholder="Enter report title"
                                                required
                                                onChange={(event) => setPayload(prevState => ({
                                                    ...prevState,
                                                    title: event.target.value
                                                }))}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicShareEmail">
                                            <Form.Label>Share <span
                                                className={"text-muted"}><small>(At least one email is required)</small></span></Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                name={"emailPayload"}
                                                value={emailPayload}
                                                placeholder="Comma seperated list of emails"
                                                onChange={(event) => {
                                                    if (error) {
                                                        setError(undefined);
                                                    }
                                                    setEmailPayload(event.target.value);
                                                }}
                                                required
                                            />
                                            {error && <small className={"text-danger"}>{error}</small>}
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Button variant="primary" type="submit" className={"w-100 mt-2"}
                                                disabled={loading}>
                                            {loading ? "Saving..." : "Save"}
                                        </Button>
                                    </div>
                                </Form>
                            </>
                    }
                </div>
            </div>
        // <div className={"d-flex flex-column align-items-center pt-5 w-100"}>
        //     <Button variant="warning" onClick={() => window.open("https://68e5-34-123-15-205.ngrok-free.app/", "_blank", "noopener,noreferrer")}>
        //         Visit SIR website
        //     </Button>
        //
        //     <Tabs
        //         activeKey={activeTab}
        //         onSelect={(tab) => setActiveTab(tab as string)}
        //         id="uncontrolled-tab-example"
        //         className="mb-3 pb-3 d-flex"
        //         style={{width: "525px", backgroundColor: "#F8F8F8"}}
        //     >
        //         <Tab
        //             eventKey="generate"
        //             title={
        //                 <div style={{width: "225px"}}>
        //                     Create Report
        //                 </div>
        //             }
        //             className={"flex-grow-1"}
        //         >
        //             <Form onSubmit={(event) => {
        //                 event.preventDefault();
        //                 handleCreateReport();
        //             }}>
        //                 {
        //                     !report ?
        //                         <div>
        //                             <Form.Group>
        //                                 <Form.Label>Report type <span
        //                                     className={"text-muted"}><small>(Google doc or spreadsheet)</small></span></Form.Label>
        //                                 <Form.Control
        //                                     as="select"
        //                                     className="mb-3"
        //                                     value={payload.type}
        //                                     onChange={(event) =>
        //                                         setPayload(prevState => ({
        //                                             ...prevState,
        //                                             type: event.target.value as "sheet" | "docs"
        //                                         }))
        //                                     }
        //                                 >
        //                                     <option value="sheet">Spreadsheet</option>
        //                                     <option value="docs">Document</option>
        //                                 </Form.Control>
        //                             </Form.Group>
        //                             <Form.Group className="mb-3" controlId="formBasicTitle">
        //                                 <Form.Label>Title <span
        //                                     className={"text-muted"}><small>(required)</small></span></Form.Label>
        //                                 <Form.Control
        //                                     type="text"
        //                                     name={"title"}
        //                                     value={payload.title}
        //                                     placeholder="Enter report title"
        //                                     required
        //                                     style={{width: '500px'}}
        //                                     onChange={(event) => setPayload(prevState => ({
        //                                         ...prevState,
        //                                         title: event.target.value
        //                                     }))}
        //                                 />
        //                             </Form.Group>
        //
        //                             <Form.Group className="mb-3" controlId="formBasicShareEmail">
        //                                 <Form.Label>Share <span
        //                                     className={"text-muted"}><small>(At least one email is required)</small></span></Form.Label>
        //                                 <Form.Control
        //                                     as="textarea"
        //                                     rows={2}
        //                                     name={"emailPayload"}
        //                                     value={emailPayload}
        //                                     placeholder="Comma seperated list of emails"
        //                                     onChange={(event) => {
        //                                         if (error){
        //                                             setError(undefined);
        //                                         }
        //                                         setEmailPayload(event.target.value);
        //                                     }}
        //                                     required
        //                                 />
        //                                 {error && <small className={"text-danger"}>{error}</small>}
        //                             </Form.Group>
        //                         </div>
        //                         :
        //                         <div className={"mt-3"}>
        //                             <Form.Group className="mb-3" controlId="formBasicId2">
        //                                 <div className={"d-flex justify-content-between align-items-center mb-1"}>
        //                                     <Form.Label>ID</Form.Label>
        //                                     <Button
        //                                         variant="primary"
        //                                         type="button"
        //                                         size={"sm"}
        //                                         className={"py-0 btn-copy"}
        //                                         onClick={handleCopyToClipboard2}
        //                                     >
        //                                         Copy
        //                                     </Button>
        //                                 </div>
        //                                 <Form.Control
        //                                     type="text"
        //                                     name={"documentId"}
        //                                     value={report.document_id}
        //                                     style={{width: '500px'}}
        //                                     disabled
        //                                 />
        //                             </Form.Group>
        //                             <Form.Group controlId="formBasicUrl">
        //                                 <div className={"d-flex justify-content-between align-items-center mb-1"}>
        //                                     <Form.Label>Url</Form.Label>
        //                                     <Button
        //                                         variant="primary"
        //                                         type="button"
        //                                         size={"sm"}
        //                                         className={"py-0 btn-copy"}
        //                                         onClick={handleCopyToClipboard}
        //                                     >
        //                                         Copy
        //                                     </Button>
        //                                 </div>
        //                                 <Form.Control
        //                                     as={"textarea"}
        //                                     rows={3}
        //                                     name={"documentUrl"}
        //                                     value={report.document_url}
        //                                     style={{width: '500px'}}
        //                                     disabled
        //                                 />
        //                             </Form.Group>
        //                         </div>
        //                 }
        //                 <div className={"d-flex justify-content-center gap-2"}>
        //                     <Button variant="secondary" type="button" className={"w-25 mt-2"} onClick={resetState}>
        //                         Reset
        //                     </Button>
        //                     <Button variant="primary" type="submit" className={"w-25 mt-2"} disabled={loading || Boolean(report)}>
        //                         {loading ? "Saving..." : "Save"}
        //                     </Button>
        //                 </div>
        //             </Form>
        //         </Tab>
        //         <Tab
        //             eventKey="fetch"
        //             title={
        //                 <div style={{width: "225px"}}>
        //                     Fetch Report
        //                 </div>
        //             }
        //             className={"flex-grow-1"}
        //         >
        //             <Form onSubmit={(event) => {
        //                 event.preventDefault();
        //                 handleFetchReport(documentId);
        //             }}>
        //                 {
        //                     !documentUrl ?
        //                         <div>
        //                             <Form.Group className={"mb-3"}>
        //                                 <Form.Label>Report type <span
        //                                     className={"text-muted"}><small>(google doc or spreadsheet)</small></span></Form.Label>
        //                                 <Form.Control
        //                                     as="select"
        //                                     className="mb-3"
        //                                     value={payload.type}
        //                                     onChange={(event) =>
        //                                         setPayload(prevState => ({
        //                                             ...prevState,
        //                                             type: event.target.value as "sheet" | "docs"
        //                                         }))
        //                                     }
        //                                 >
        //                                     <option value="sheet">Spreadsheet</option>
        //                                     <option value="docs">Document</option>
        //                                 </Form.Control>
        //                             </Form.Group>
        //                             <Form.Group className="mb-3" controlId="formBasicId">
        //                                 <Form.Label>Report ID <span
        //                                     className={"text-muted"}><small>(required)</small></span></Form.Label>
        //                                 <Form.Control
        //                                     type="text"
        //                                     name={"documentId"}
        //                                     value={documentId}
        //                                     placeholder="Enter report ID"
        //                                     style={{width: '500px'}}
        //                                     onChange={(event) => setDocumentId(event.target.value)}
        //                                     required
        //                                 />
        //                             </Form.Group>
        //                         </div>
        //                         :
        //                         <div className={"d-flex mt-4 gap-2"}>
        //                             <Form.Group controlId="formBasicUrl">
        //                                 <div className={"d-flex justify-content-between align-items-center mb-1"}>
        //                                     <Form.Label>Url</Form.Label>
        //                                     <Button
        //                                         variant="primary"
        //                                         type="button"
        //                                         size={"sm"}
        //                                         className={"py-0 btn-copy"}
        //                                         onClick={handleCopyToClipboard}
        //                                     >
        //                                         Copy
        //                                     </Button>
        //                                 </div>
        //                                 <Form.Control
        //                                     as={"textarea"}
        //                                     rows={3}
        //                                     name={"documentUrl"}
        //                                     value={documentUrl}
        //                                     style={{width: '500px'}}
        //                                     disabled
        //                                 />
        //                             </Form.Group>
        //                         </div>
        //                 }
        //                 <div className={"d-flex justify-content-center gap-2"}>
        //                     <Button variant="secondary" type="button" className={"w-25 mt-2"}
        //                             onClick={resetState}>
        //                         Reset
        //                     </Button>
        //                     <Button variant="primary" type="submit" className={"w-25 mt-2"} disabled={Boolean(documentUrl)}>
        //                         Fetch
        //                     </Button>
        //                 </div>
        //             </Form>
        //         </Tab>
        //     </Tabs>
        // </div>
    )
}
export default SirReportPage;