import React, {useEffect} from "react";
import {Drawing} from "../../../models/Drawing";
import rvtIcon from "../../../assets/icons/rvt.png";
import {useApiClient} from "../../../network/client/UseApiClient";
import {ServerFile} from "../../../models/Document";
import {getDocumentDetails} from "../../../services/DocumentService";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export const DrawingCard = (props: { drawing: Drawing, onClick: (evt: React.MouseEvent) => void }) => {

    const [document, documentState, docError, fetchDocDetails] = useApiClient<ServerFile>();

    useEffect(() => {
        fetchDocDetails(getDocumentDetails(props.drawing.documentId || '')).then().catch(err => {
            console.error("#15",err);
        })
    }, []);

    return <div className={`drawingCard`}>
        <OverlayTrigger placement={"bottom"} delay={{show: 250, hide: 400}}
                        overlay={<Tooltip>{props.drawing?.projectName}</Tooltip>}>
            <div className={"body"}>
                <small
                    style={{fontSize: "0.7rem", color: "#555", fontWeight: "900"}}>{props.drawing?.projectName}</small>
            </div>
        </OverlayTrigger>
        <div className={`header`} onClick={props.onClick}>
            <img className={`icon`} src={rvtIcon}/>
        </div>
        <OverlayTrigger placement={"bottom"} delay={{show: 250, hide: 400}}
                        overlay={<Tooltip>{document?.name || "unknown"}</Tooltip>}>
            <div className={"body"}>
                <small>{document?.name || "unknown"}</small>
            </div>
        </OverlayTrigger>
    </div>;
}