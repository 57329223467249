import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from "../common/AppConstants";

// Initialize Google Analytics
export const initGA = () => {
  console.log("Initializing GA Metric Library with the mesaurement ID " + GOOGLE_ANALYTICS_MEASUREMENT_ID)
  ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID ?? "");
};

// Log a page view
export const logPageView = (path: string) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}


// Log custom events
export const logEvent = (category: string, action: string, label?: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

const extractEmailDomain = (email: string): string | null => {
  const match = email.match(/@(.+)$/);
  return match ? match[1] : null;
};

export const sendEmailDomainCapturedEvent = (userName: string) => {
  const domainStr = extractEmailDomain(userName)
  if (domainStr) {
    logEvent("EMail Domain", "Catch Email", domainStr)
    console.info(`Username ${userName} Domain ${domainStr}`)
  } else {
    console.error(`Domain name not found for use ${userName}`)
  }
}

export const sendCustomDimensionToGTM = (dimensionName: string, email: string) => {
  if (typeof window !== "undefined") {
    const domainStr = extractEmailDomain(email)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "customDimension",
      [dimensionName]: domainStr, // E.g., user_type: 'admin'
    });
  }
};