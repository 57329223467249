import "./ProjectGuideModal.scss";
import {Modal, ModalBody} from "react-bootstrap";
import defaultProjectImg from '../../assets/images/defaultProjectImg.png';

export const SetDefaultProjectGuideModal = (props: { show: boolean, onHide: () => void }) => {
    return <Modal show={props.show} onHide={props.onHide} className={`bg-transparent`} centered dialogClassName={"project_guide_modal"}>
        <ModalBody className={ ``}>
            <div className={`bg-white h-auto `}>
                <h6 className={`text-center`}>Please Select a project first as Default to start a chat conversation or select a project from project
                    listing page.</h6>
                <div className={`d-flex justify-content-center align-items-center mt-4`}>
                    <div>
                        <img src={defaultProjectImg} style={{width: '100%', objectFit: 'contain'}}/>
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>;
}