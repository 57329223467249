import {ApiContract} from "../network/dto/ApiContract";

const getPendingApprovals = () : ApiContract => {
    return {url : `/api/administrator/pending-users` , method : "GET"}
}

const approveUser = (id: number) : ApiContract => {
    return {url : `/api/administrator/approve-user/${id}` , method : "POST"}
}

const rejectUser = (id: number) : ApiContract => {
    return {url : `/api/administrator/reject-user/${id}` , method : "POST"}
}

export {getPendingApprovals, approveUser, rejectUser}
