import "./reset-password.scss";
import {Button} from "../../../components/button/Button";
import {NetworkState} from "../../../network/dto/ApiContract";
import {useInputHandler} from "../../../hooks/UseInputHandler";
import {PasswordResetPayload, Token} from "../../../models/LoginModels";
import {useApiClient} from "../../../network/client/UseApiClient";
import React, {FormEvent} from "react";
import {PasswordResetApiContract} from "../../../services/LoginService";
import {v4} from "uuid";
import {useParams} from "react-router";
import {useToast} from "../../../components/toast/ToastManager";
import log from "../../../assets/images/logo.svg";
import {useNavigate} from "react-router-dom";

export const ResetPassword = () => {
    const {payload, handleChange} = useInputHandler<PasswordResetPayload>({password: '', confirmPassword: ''});
    const [token, loginApiState, loginError, executeLoginApi] = useApiClient<Token>();
    const toastManager = useToast();
    const {uid} = useParams();
    const navigate = useNavigate();

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (payload.password === payload.confirmPassword) {
            executeLoginApi(PasswordResetApiContract(uid || '', payload)).then(res => {
                toastManager.show({id: v4(), title: "Password reset successfully!", isSuccess: true, duration: 4000});
                navigate("/login");
            }).catch(err => {
                toastManager.show({id: v4(), title: "Failed to reset password", isSuccess: false, duration: 4000});
                console.error("password reset error", err)
            });
        } else {
            toastManager.show({
                id: v4(),
                title: "New password is not matching with confirm password.",
                isSuccess: false,
                duration: 8000
            })
        }
    }
    return (
        <div className={"d-flex flex-column h-100 position-relative"}>
            <img src={log} style={{width: "7rem", height: "3rem", margin: "1rem"}} alt={`_logo`}/>
            <div className={`position-absolute d-flex justify-content-center align-items-center w-100 h-100`}>
                <div className={`resetPasswordPanel shadow`}>
                    <h4 style={{fontWeight: '500'}} className={`text-center text-primary`}>Reset Password</h4>
                    <form onSubmit={onSubmit} className={`mt-3`}>
                        <div className={'mb-3'}>
                            <label htmlFor={`username`} className={`form-label`}>New Password</label>
                            <input className={`form-control`} type={`password`} id={`password`} name={`password`}
                                   value={payload.password} onChange={handleChange} required/>
                        </div>
                        <div className={'mb-3 mt-4'}>
                            <label htmlFor={`username`} className={`form-label`}>Confirm Password</label>
                            <input className={`form-control`} type={`password`} id={`confirmPassword`}
                                   name={`confirmPassword`}
                                   value={payload.confirmPassword} onChange={handleChange} required/>
                        </div>
                        <Button type={"submit"} loading={loginApiState === NetworkState.Loading}
                                className={`btn btn-primary w-100`}
                                loaderText={`Saving...`}>
                            Save Password
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}