import React from 'react';
import {useLocation} from "react-router";
import log from "../../assets/images/logo.svg";

const CheckEmailPage = () => {

    const location = useLocation();
    const {emailId} = location.state || {};

    return (
        <div className={"d-flex flex-column h-100 position-relative"}>
            <img src={log} style={{width: "7rem", height: "3rem", margin: "1rem"}} alt={`_logo`}/>
            <div className={`position-absolute d-flex justify-content-center align-items-center w-100 h-100`}>
                <div
                    className="d-flex flex-column justify-content-center align-items-center vh-100 text-center">
                    <img
                        src="https://img.icons8.com/fluency/96/new-post.png"
                        alt="Email Icon"
                        width="60"
                        className="mb-3"
                    />
                    <h2 className="fw-bold">Please verify your email</h2>
                    <p className="text-muted">You're almost there! We sent an email to</p>
                    <p className="fw-semibold">{emailId}</p>
                    <p className="text-muted">
                        Just click on the link in that email to complete your signup. If you don't see it, you may need
                        to{" "}
                        <span className="fw-bold">check your spam</span> folder.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CheckEmailPage;
