import log from "../../assets/images/logo.svg";
import userIcon from "../../assets/icons/user.svg";
import './appHeader.styles.scss';
import {useSessionManager} from "../../hooks/UseSessionManager";
import React, {useEffect, useState} from "react";
import {useApiClient} from "../../network/client/UseApiClient";
import {UserRole, UsersModel} from "../../models/UsersModel";
import {AccountDetailApi} from "../../services/AccountService";
import {NetworkState} from "../../network/dto/ApiContract";
import {Dropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {getUserRoleLabel} from "../../utils/AppUtils";
import {NotificationModal} from "../notifications/NotificationModal";
import {LogoutModal} from "../logout/LogoutModal";
import {TabMenuItem} from "../tab/TabMenuItem";
import dashboardIcon from "../../assets/icons/dashboard.png";
import {matchPath, useLocation} from "react-router";
import manageIcon from "../../assets/icons/manage.png";
import reportIcon from "../../assets/icons/report.png";

export const AppHeader = () => {
    const {setChatUser} = useSessionManager();
    const [user, userNetworkState, error, userData] = useApiClient<UsersModel>();
    const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false)
    const navigate = useNavigate();
    const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
    const path = useLocation();


    useEffect(() => {
        userData(AccountDetailApi())
    }, []);

    useEffect(() => {
        if (userNetworkState === NetworkState.Loaded) {
            setChatUser({
                quivrId: user?.quivrId || '',
                user_name: user?.fullname || '',
                brainId: user?.organization?.brainId || '',
                role: user?.role || UserRole.Member

            });
        }
    }, [userNetworkState])


    return <header className={` bg-white sticky-top`}>
        <div className={`appHeader`}>
            <div className={`col align-self-center`}>
                <img src={log} className={`logo`} alt={`_logo`}/>
            </div>
            <div>
                <div className={`position-relative d-flex justify-content-center border-bottom bg-white z-1`}>
                    <div className={`d-flex justify-content-between`}>
                        <nav className="nav flex-column flex-sm-row nav-tabs my-2">
                            <TabMenuItem icon={dashboardIcon} label={'Dashboard'} pathname={'/'}
                                         isActive={!!matchPath("/", path.pathname)}/>
                            <TabMenuItem icon={manageIcon} label={'Manage'} pathname={'/manage'}
                                         isActive={!!matchPath("/manage/*", path.pathname)}/>
                            <TabMenuItem icon={reportIcon} label={'Sir Report'} pathname={'/report'}
                                         isActive={!!matchPath("/report/*", path.pathname)}/>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-start d-flex menuBar col justify-content-end">
                <div className={`align-self-center d-flex align-items-center`}>
                    <div className={`me-2`}><span className={`bi bi-bell text-dark fs-4 cursor-pointer`}
                                                  onClick={() => {
                                                      setShowNotificationModal(true)
                                                  }}/></div>
                    <div className={`me-4 small fw-semibold`}>{user?.organization?.name}</div>
                </div>
                <Dropdown className={'align-self-center'}>
                    <Dropdown.Toggle id="dropdown-basic"
                                     className="p-0 bg-transparent border-0 d-flex text-black dropDown_toggle align-items-center">
                        <img src={userIcon} className={`icon me-1`}/>
                        <small>{user?.fullname?.split(" ")[0] || 'Unknown'} ({getUserRoleLabel(user?.role || UserRole.Unknown)})</small>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropDown_menu bg-white w-100" style={{borderRadius: "10px"}}>
                        <Dropdown.Item className={'border-bottom'} onClick={() => navigate(`/settings/users`)
                        }><i className="bi bi-people fs-6 me-2"></i>Admin Settings</Dropdown.Item>
                        <Dropdown.Item onClick={(e) => {
                            setShowLogoutModal(true);
                        }}><i className="bi bi-box-arrow-right fs-6 me-2"></i>
                            Log out
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <NotificationModal show={showNotificationModal} onHide={() => {
                setShowNotificationModal(false)
            }}/>
            <LogoutModal show={showLogoutModal} onHide={() => setShowLogoutModal(false)}/>
        </div>
    </header>
}