import "./projectCardList.scss";
import React, {Fragment, useContext, useState} from 'react';
import ProjectGridCard from "./cards/ProjectGridCard";
import {Project} from "../../../../models/Project";
import {useNavigate} from "react-router-dom";
import {DefaultProjectContext} from "../../../../hooks/DefaultProject";
import DataTable, {TableColumn, TableStyles} from "react-data-table-component";
import moment from "moment/moment";
import {Badge} from "react-bootstrap";
import chevron_up from "../../../../assets/icons/chevron_up.png";
import chevron_down from "../../../../assets/icons/chevron_down.png";
import chevrons_up from "../../../../assets/icons/chevrons_up.png";
import {useSessionManager} from "../../../../hooks/UseSessionManager";
import {UserRole} from "../../../../models/UsersModel";
import ConfirmationModal from "../../../../components/confirmationDialog/ConfirmationModal";
import {DeleteProjectApiContract} from "../../../../services/ProjectService";
import {useApiClient} from "../../../../network/client/UseApiClient";
import {ProjectContext} from "../../../../context/ProjectContext";
import {NetworkState} from "../../../../network/dto/ApiContract";

interface ProjectCardListProps {
    projects: Project[]
    viewType: 'GRID' | 'LIST'
}

const Projects: React.FC<ProjectCardListProps> = ({viewType, projects}) => {

    const dps = useContext(DefaultProjectContext);
    const {refreshProjects, projectNetworkState} = useContext(ProjectContext);
    const navigate = useNavigate();
    const {chatUser} = useSessionManager();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [project,projectFetchingNetworkState, error, deleteProject] = useApiClient<Project>();
    const [deleteId, setDeleteId] = useState<string | undefined>(undefined);


    const handleClickAction = (id: string) => {
        navigate(`project/${id}/documents`);
    }

    const removeProject=(id?:string)=>{
        deleteProject(DeleteProjectApiContract(id)).then(()=> {
            refreshProjects && refreshProjects();
        }).catch((error) => {
            console.log("failed to delete project");
        })
    }

    const columns: TableColumn<Project>[] = [
        {
            name: 'Name',
            cell: row => <div className={"text-truncate fw-semibold"} onClick={() => handleClickAction(row.id ?? "")}>{row.name}</div>,
            width: "25%",
        },
        {
            name: 'Dates',
            cell: (row) => formatDateRange(row.startDate ?? "", row.finishDate ?? ""),
        },
        {
            name: 'Status',
            cell: row => getProjectStatus(row.status ?? ""),
        },
        {
            name: 'Priority',
            cell: row => getProjectPriority(row.priority ?? ""),
        },
        {
            name: 'Last updated',
            cell: row => <div>{moment(row.lastUpdated).format('MMM  DD')}</div>,
        },
        {
            name: 'Actions',
            cell: row => getActions(row.id ?? ""),
        }
    ];

    const getActions = (projectId: string) => {
        return (
            <div className={"d-flex align-items-center"}>
                <i className={"bi bi-pencil me-2 cursor-pointer"}
                   onClick={() => navigate(`/manage/project/edit-project/${projectId}`)}
                ></i>
                {
                    chatUser?.role === UserRole.SuperAdmin &&
                    <i className={"bi bi-trash cursor-pointer"}
                       onClick={() => {
                           setShowConfirmationDialog(true);
                           setDeleteId(projectId);
                       }}
                    ></i>
                }
            </div>
        )
    }

    const formatDateRange = (startDate: string, finishDate: string) => {
        return (
            <div>
                {moment(startDate).format('MMM  DD')} - {moment(finishDate).format('MMM DD')}
            </div>
        )
    }

    const getProjectStatus = (status: string) => {
        return (
            <Badge
                bg={"none"}
                id={status === "InProgress" ? "status_progress" : "status_done"}
                className={"status_badge"}
            >
                {status === "InProgress" ? "In Progress" : status}
            </Badge>
        )
    }

    const getProjectPriority = (priority: string) => {
        return (
            <div className={"d-flex align-items-center fw-medium"}>
                <img
                    src={priority === "Low" ? chevron_down : priority === "High" ? chevrons_up : chevron_up}
                    alt={"chevron"}
                />
                <div
                    className={priority === "Low" ? "text-success" : priority === "High" ? "text-danger" : "text-warning"}>
                    {priority}
                </div>
            </div>
        )
    }

    const customStyles: TableStyles = {
        head: {
            style: {
                border: "0.1px solid rgba(22, 61, 118, 0.2)",
                borderBottom: "none"
            }
        },
        headCells: {
            style: {
                fontSize: '1rem',
                fontWeight: 'bold',
                backgroundColor: "#163D760A"
            },
        },
        cells: {
            style: {
                fontSize: '0.9rem',
            },
        }
    }


    return <Fragment>
        {
            viewType === 'GRID' ?
                <div className="row row-cols-4 g-4 projects step-1"
                     key={dps.defaultProject?.id}
                >
                    <Fragment>
                        {projects.map((project, idx) => <ProjectGridCard
                            isDefault={dps.defaultProject?.id === project.id}
                            key={project.id}
                            data={project}
                            idx={idx}
                            onClick={() => {
                                handleClickAction(project?.id || '')
                            }}
                            onDelete={() => project}
                            onSetDefault={() => {
                                if (dps.setDefaultProject) {
                                    dps.setDefaultProject(project);
                                }
                            }}
                        />)
                        }
                    </Fragment>
                </div> :
                <DataTable
                    customStyles={customStyles}
                    columns={columns}
                    data={projects}
                    highlightOnHover
                    responsive
                    progressPending={projectNetworkState === NetworkState.Loading}
                    onRowClicked={(row) => handleClickAction(row.id ?? "")}
                />

        }
        <ConfirmationModal
            title={'Delete Project'}
            message={'Are you sure you want to delete this Project?'}
            onSubmit={() => {
                removeProject(deleteId);
                setShowConfirmationDialog(false);
            }}
            onCancel={() => {
                setDeleteId(undefined);
                setShowConfirmationDialog(false);
            }}
            show={showConfirmationDialog}
            btnText={'Delete'}
            centered={true}
        />
    </Fragment>;
};

export default Projects;