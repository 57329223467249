import React from "react";
import log from "../../assets/images/logo.svg";

const RequestSentPage = () => {

    return (
        <div className={"d-flex flex-column h-100 position-relative"}>
            <img src={log} style={{width: "7rem", height: "3rem", margin: "1rem"}} alt={`_logo`}/>
            <div className={`position-absolute d-flex justify-content-center align-items-center w-100 h-100`}>
                <div
                    className="d-flex flex-column justify-content-center align-items-center text-center">
                    <img src="https://img.icons8.com/color/96/checked--v1.png" alt="Success" width="60"
                         className="mb-3"/>
                    <h2 className="fw-bold">Account Request Submitted</h2>
                    <p className="text-muted">Your request to create an account has been successfully submitted.</p>
                    <p className="text-muted">Please wait for approval. Once approved, you will receive a confirmation
                        email.</p>
                </div>
            </div>
        </div>
    );
};

export default RequestSentPage;