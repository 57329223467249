import React, {useState} from 'react';
import './projectCard.scss';
import {Project} from "../../../../../models/Project";
import {DropdownButton, DropdownItem} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useApiClient} from "../../../../../network/client/UseApiClient";
import {DeleteProjectApiContract} from "../../../../../services/ProjectService";
import ConfirmationModal from "../../../../../components/confirmationDialog/ConfirmationModal";
import {useSessionManager} from "../../../../../hooks/UseSessionManager";
import {UserRole, UsersModel} from '../../../../../models/UsersModel';

interface ProjectCardProps {
    data?: Project;
    onClick: () => void
    onDelete: () => void;
    isDefault: boolean;
    onSetDefault: () => void,
    idx: number
}

const ProjectGridCard: React.FC<ProjectCardProps> = ({data, onClick, onDelete, isDefault, onSetDefault, idx}) => {
    const [user] = useApiClient<UsersModel>();
    const {chatUser} = useSessionManager();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [project, projectNetworkState, error, deleteProject] = useApiClient<Project>();
    const navigate = useNavigate();

    const removeProject = (id?: string) => {
        deleteProject(DeleteProjectApiContract(id)).then(() => {
            onDelete()
        })
    }

    return (
        <div className={`col`}>
            <div className={`card w-100 overflow-hidden ${isDefault ? 'default' : ''}`} onClick={() => {
                onClick()
            }}>
                {data?.profilePicture && data.profilePicture.trim() !== '' ? <img src={data?.profilePicture} style={{
                    height: '100%',
                    position: 'absolute',
                    width: '100%',
                    backgroundColor: "white"
                }}/> : null}
                <div className={'d-flex justify-content-between'}>
                    <div><label>{data?.name}</label></div>
                    <div onClick={(evt) => {
                        evt.stopPropagation();
                    }} className={`mt-3`}><DropdownButton
                        drop={`end`}
                        title={<div style={{backgroundColor: '#0008', height: '28px', width: '28px'}}
                                    className={`d-flex align-items-center justify-content-center rounded border`}><span
                            onClick={(evt) => ''}
                            className={'text-white bi bi-three-dots-vertical'}/></div>}
                        className={`text-white bg-transparent me-2`}>
                        <DropdownItem className={``} onClick={(evt) => {
                            evt.stopPropagation();
                            navigate(`/manage/project/edit-project/${data?.id}`)
                        }}><span className={`bi bi-pencil me-2`}/>Edit</DropdownItem>
                        {(chatUser?.role === UserRole.SuperAdmin &&
                            <DropdownItem className={`text-danger`} onClick={(evt) => {
                                evt.stopPropagation();
                                setShowConfirmationDialog(true)
                            }}><span className={`bi bi-trash me-2 text-danger`}/> Delete</DropdownItem>
                        )}
                    </DropdownButton>
                    </div>

                </div>
                <div className={`overlay`}/>
            </div>
            <ConfirmationModal title={'Delete Project'} message={'Are you sure you want to delete this Project?'}
                               onSubmit={() => {
                                   removeProject(data?.id);
                                   setShowConfirmationDialog(false)
                               }} onCancel={() => setShowConfirmationDialog(false)} show={showConfirmationDialog}
                               btnText={'Delete'}
            />
        </div>
    );
}

export default ProjectGridCard;