import "./Registration.scss";
import React, {useState} from 'react';
import {useNavigate, Link} from "react-router-dom";
import {useApiClient} from "../../network/client/UseApiClient";
import {registerUserApiContract} from "../../services/RegistrationService";
import log from "../../assets/images/logo.svg";
import {Button} from "../../components/button/Button";
import {NetworkState} from "../../network/dto/ApiContract";
import {AllToasts} from "../../components/toast/AllToasts";


const RegisterPage = () => {

    const navigate = useNavigate();
    const [data, networkState, error, execute] = useApiClient();


    const [formErrors, setFormErrors] = useState({
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        organization: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        password: '',
        confirmPassword: '',
    });

    const [formData, setFormData] = useState({
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        organization: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        password: '',
        confirmPassword: '',
        jobTitle: '',
        termsAccepted: true,
    });

    const [showTerms, setShowTerms] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value, type, checked} = e.target;
        console.log(`Field updated: ${name} = ${type === 'checkbox' ? checked : value}`);
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Form data at submission:', formData);

        let errors = {
            username: '',
            firstname: '',
            lastname: '',
            email: '',
            phoneNumber: '',
            organization: '',
            street: '',
            city: '',
            state: '',
            zipcode: '',
            password: '',
            confirmPassword: '',
        };
        let hasError = false;
        const restrictedDomains = ["gmail.com", "hotmail.com", "yahoo.com", "outlook.com", "live.com", "aol.com"];

        // Validation logic
        if (!formData.firstname.trim()) {
            errors.firstname = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN FIRST NAME');
        }
        if (!formData.lastname.trim()) {
            errors.lastname = "Field cannot be empty";
            hasError = true;
        }
        if (!formData.email.trim()) {
            errors.email = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN LAST NAME');
        }
        if (!formData.organization.trim()) {
            errors.organization = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN ORGANIZATION');
        }

        if (!formData.password.trim()) {
            errors.password = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN PASSWORD');
        }
        if (!formData.confirmPassword.trim()) {
            errors.confirmPassword = "Field cannot be empty";
            hasError = true;
            console.log('ERROR IN CONFIRM PASSWORD');
        }
        if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
            hasError = true;
            console.log('ERROR IN PASSWORD DONT MATCH');
        }
        if (formData.email) {
            const emailParts = formData.email.split('@');
            if (emailParts.length !== 2 || restrictedDomains.includes(emailParts[1].toLowerCase())) {
                errors.email = "Please use a business email address";
                hasError = true;
                console.log('ERROR: Non-business email used');
            }
        }

        console.log('Validation errors:', errors);
        console.log('Has validation error:', hasError);
        setFormErrors(errors);

        if (hasError) {
            console.log("Validation failed, stopping submission.");
            return;
        }

        console.log('NO ERRORS Submitting form data:', formData);
        try {
            console.log("Sending data to server:", formData);

            const responseData: any = await execute(registerUserApiContract({...formData, username: formData.email}));

            console.log("Parsed response data:", responseData);

            if (responseData) {
                console.log("Form submission successful!");
                navigate('/check-email', {state: {emailId: responseData.email}});
            } else {
                console.error("Form submission failed. Network state:", networkState);
            }

        } catch (error: any) {
            console.error("Error during form submission:", error);


            if (error?.response?.data) {
                console.log("Server error response:", error.response.data);
                AllToasts.failure("Error occurred")
            } else {
                AllToasts.failure("Error occurred");
            }
        }
    };

    return (
        <div className={"d-flex flex-column h-100 position-relative"}>
            <img src={log} style={{width: "7rem", height: "3rem", margin: "1rem"}} alt={`_logo`}/>
            <div className={`position-absolute d-flex justify-content-center align-items-center w-100 h-100`}>
                <div className={`registerPanel shadow`}>
                    <h4 style={{fontWeight: '500'}} className={`text-center text-primary`}>Register at dodda.ai
                    </h4>
                    <form onSubmit={handleSubmit} className={`mt-3 d-flex flex-column gap-2`}>
                        <div className={"d-flex gap-2 mb-3"}>
                            <div className={"w-50"}>
                                <label htmlFor="firstname" className="form-label">First Name <span
                                    className={"text-danger"}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control py-1"
                                    id="firstname"
                                    name="firstname"
                                    value={formData.firstname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className={"w-50"}>
                                <label htmlFor="lastname" className="form-label">Last Name <span
                                    className={"text-danger"}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control py-1"
                                    id="lastname"
                                    name="lastname"
                                    value={formData.lastname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Business Email <span
                                className={"text-danger"}>*</span></label>
                            <input
                                type="text"
                                className="form-control py-1"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.email && <div className="text-danger small">{formErrors.email}</div>}
                        </div>
                        <div className={"d-flex gap-2 mb-3"}>
                            <div className={"w-50"}>
                                <label htmlFor="password" className="form-label">Password <span
                                    className={"text-danger"}>*</span></label>
                                <input
                                    type="password"
                                    className="form-control py-1"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className={"w-50"}>
                                <label htmlFor="confirmPassword" className="form-label">Confirm Password <span
                                    className={"text-danger"}>*</span></label>
                                <input
                                    type="password"
                                    className="form-control py-1"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="organization" className="form-label">Organization <span
                                className={"text-danger"}>*</span></label>
                            <input
                                type="text"
                                className="form-control py-1"
                                id="organization"
                                name="organization"
                                value={formData.organization}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className={"d-flex gap-2 mb-3"}>
                            <div className={'w-50'}>
                                <label htmlFor={`phoneNumber`} className={`form-label`}>Phone</label>
                                <input className={`form-control py-1`}
                                       type={`text`}
                                       id={`phoneNumber`}
                                       name={`phoneNumber`}
                                       value={formData.phoneNumber}
                                       onChange={handleChange}
                                />
                            </div>
                            <div className="w-50">
                                <label htmlFor="city" className="form-label">City</label>
                                <input
                                    type="text"
                                    className="form-control py-1"
                                    id="city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <Button type={"submit"}
                                loading={networkState === NetworkState.Loading}
                                className={`btn btn-primary w-100`}
                        >
                            Register
                        </Button>
                        <label className={"text-center w-100 mt-3"}>Already a user?
                            <span className={"text-primary cursor-pointer"}
                                  onClick={() => navigate("/login")}
                            > Log in
                        </span>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
