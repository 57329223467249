import React, {useEffect, useState} from "react";
import {approveUser, getPendingApprovals, rejectUser} from "../../services/AdminService";
import {useApiClient} from "../../network/client/UseApiClient";
import {RegistrationModel} from "../../models/RegistrationModel";
import {Button} from "react-bootstrap";

const AdminPortal = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [pendingUsers, pendingUsersNetworkState, error, fetchPendingUsers] = useApiClient<RegistrationModel[]>();
    const [_,approveRejectNetworkState, approveRejecterror, handleRegistrations] = useApiClient<string>();
    const [btnLoading, setBtnLoading] = useState<boolean>(false);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        setLoading(true);
        await fetchPendingUsers(getPendingApprovals());
        setLoading(false);
    };

    const handleApprove = async (id: number) => {
        setBtnLoading(true);
        await handleRegistrations(approveUser(id));
        setBtnLoading(false);
        loadUsers();
    };

    const handleReject = async (id: number) => {
        setBtnLoading(true);
        await handleRegistrations(rejectUser(id));
        setBtnLoading(false);
        loadUsers();
    };

    return (
        <div className="container my-4">
            {loading ? (
                <p>Loading users...</p>
            ) : (
                <table className="table table-striped table-hover table-bordered mt-3">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Organization</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingUsers && pendingUsers.map((user) => (
                            <tr key={user.id}>
                                <td>{user.firstname} {user.lastname}</td>
                                <td>{user.email}</td>
                                <td>{user.organization}</td>
                                <td>
                                    <Button className="btn btn-success me-2" disabled={btnLoading} onClick={() => {handleApprove(user.id ?? 0)}}>Approve</Button>
                                    <Button className="btn btn-danger" disabled={btnLoading} onClick={() => {handleReject(user.id ?? 0)}}>Reject</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AdminPortal;
