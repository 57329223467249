import {matchPath, Route, Routes, useLocation} from "react-router";
import {McDashboardPage} from "../dashboard/McDashboardPage";
import {McManagePage} from "../manage/McManagePage";
import {McProcurePage} from "../procure/McProcurePage";
import {McMarketplacePage} from "../marketplace/McMarketplacePage";
import './manageConsole.scss';
import {AppHeader} from "../../../components/header/AppHeader";
import React, {useContext, useEffect, useState} from "react";
import {ProjectDetailPage} from "../manage/project/details/ProjectDetailPage";
import Users from "../../manageUsers/Users";
import {Modal} from "react-bootstrap";
import {AddNewProject} from "../manage/project/addNewProject/AddNewProject";
import {ChatModal} from "../../chatroom/ChatModal";
import AdminSettings from "../../adminSettings/AdminSettings";
import {SetDefaultProjectGuideModal} from "../../../components/guide/SetDefaultProjectGuideModal";
import {DefaultProjectContext} from "../../../hooks/DefaultProject";
import {ProjectSidebar} from "../manage/sidebar/ProjectSidebar";
import {ProjectContext, ProjectProvider} from "../../../context/ProjectContext";
import {TabMenuItem} from "../../../components/tab/TabMenuItem";
import dashboardIcon from "../../../assets/icons/dashboard.png";
import manageIcon from "../../../assets/icons/manage.png";
import {useAppContext} from "../../../context/AppContext";
import ChatBot from "../../../assets/images/Chatbot.svg";
import SirReportPage from "../report/SirReportPage";

export const ManagementConsolePage = () => {
    const path = useLocation();
    const [activeMenu, setActiveMenu] = useState<string>("");
    const [showChat, setShowChat] = useState<boolean>(false);
    const dps = useContext(DefaultProjectContext);
    const [showSetDefaultProjectGuide, setShowSetDefaultProjectGuide] = useState<boolean>(false);
    const {onChatIconClick} = useAppContext();

    useEffect(()=> {
        if (path) {
            setActiveMenu(path.pathname)
            setShowChat(false)
        }
    }, [path])

    return <div className={`d-flex flex-column h-100`}>
        <AppHeader/>
        <ProjectProvider>
            <div className={`flex-fill bg-body pageContainer`}>
                {
                    (matchPath("/manage", path.pathname) || (matchPath("/manage/project/:projectId/*", path.pathname) && !matchPath("/manage/project/addProject", path.pathname) && !matchPath("/manage/project/edit-Project/*", path.pathname))) &&
                    <div className={`sidebar`}>
                        <ProjectSidebar/>
                    </div>
                }
                <main>
                    <Routes>
                        <Route path={'/'} element={<McDashboardPage/>}/>
                        <Route path={'/manage'} element={<McManagePage/>}/>
                        <Route path={'/report'} element={<SirReportPage/>}/>
                        <Route path={'/manage/project/addProject'} element={<AddNewProject/>}/>
                        <Route path={'/manage/project/edit-project/:projectId'} element={<AddNewProject/>}/>
                        <Route path={'/manage/project/:projectId/*'} element={<ProjectDetailPage/>} />
                        <Route path={'/procure'} element={<McProcurePage/>}/>
                        <Route path={'/marketplace'} element={<McMarketplacePage/>}/>
                        <Route path={'/users'} element={<Users/>}/>
                        <Route path={'/settings/*'} element={<AdminSettings/>}/>
                    </Routes>
                </main>
            </div>
        </ProjectProvider>
        <Modal className={`overflow-hidden border-0 shadow-sm modal modal-xl vh-100`} dialogClassName={"initialize_chat_modal"} show={showChat} onHide={()=> {setShowChat(false)}}><ChatModal onClose={()=> setShowChat(false)} /></Modal>
        <SetDefaultProjectGuideModal show={showSetDefaultProjectGuide} onHide={()=> setShowSetDefaultProjectGuide(false)} />
        {
            (matchPath("/manage", path.pathname) || (matchPath("/manage/project/:projectId/*", path.pathname) && !matchPath("/manage/project/addProject", path.pathname) && !matchPath("/manage/project/edit-Project/*", path.pathname))) &&
            <img
                className={"chatbot"}
                src={ChatBot}
                alt={"chatbot"}
                onClick={() => {
                    if (dps.activeBrains && (dps.activeBrains.length || 0) > 0) {
                        setShowChat(prev => !prev);
                    } else {
                        onChatIconClick();
                    }
                }}
            />
        }
    </div>;
}