// import React from "react";
// import PropTypes from "prop-types";
// import "./Pagination.scss";
//
// interface Props {
//     currentPage: number;
//     totalPages: number;
//     onPageChange: (page: number) => void;
//     totalElements: number;
// }
//
// const Pagination: React.FC<Props> = (props) => {
//     const getPageNumbers = (): (number | string)[] => {
//         const pageNumbers: (number | string)[] = [];
//         const { currentPage, totalPages } = props;
//
//         if (totalPages <= 1) {
//             return pageNumbers;
//         }
//
//         const startPage = Math.max(currentPage - 1, 1);
//         const endPage = Math.min(currentPage + 1, totalPages - 1);
//
//         pageNumbers.push(0);
//
//         if (currentPage > 2) {
//             pageNumbers.push("...");
//         }
//
//         for (let i = startPage; i <= endPage; i++) {
//             pageNumbers.push(i);
//         }
//
//         if (endPage < totalPages - 2) {
//             pageNumbers.push("...");
//         }
//
//         if (totalPages > 1 && !pageNumbers.includes(totalPages - 1)) {
//             pageNumbers.push(totalPages - 1);
//         }
//
//         return pageNumbers;
//     };
//
//     const onPageClick = (page: number) => {
//         if (page >= 0 && page < props.totalPages) {
//             props.onPageChange(page);
//         }
//     };
//
//     const pageNumbers: (number | string)[] = getPageNumbers();
//
//     return props.totalPages <= 1?null:(
//         <div className={"paginate mt-3"}>
//             <div className={"pagination"}>
//                 <div className="pageInputContainer">
//           <span className="p-2">
//             Showing {props.currentPage + 1} of {props.totalPages}
//           </span>
//                 </div>
//             </div>
//
//             <div className={"d-flex buttonFooter align-items-center"}>
//                 <button
//                     className={"btn btn-sm btn-outline-secondary me-1"}
//                     onClick={() => onPageClick(props.currentPage - 1)}
//                     disabled={props.currentPage === 0}>
//                     <i className="bi bi-chevron-left"></i>
//                 </button>
//
//                 {pageNumbers.map((pageNumber: number | string, index: number) => (
//                     <button
//                         key={index}
//                         className={`btn btn-sm me-1 ${
//                             typeof pageNumber === "number" && props.currentPage === pageNumber
//                                 ? "btn-primary"
//                                 : "btn-outline-primary"
//                         }`}
//                         onClick={() => {
//                             if (typeof pageNumber === "number") {
//                                 onPageClick(pageNumber);
//                             }
//                         }}
//                     >
//                         {typeof pageNumber === "number" ? pageNumber + 1 : pageNumber}
//                     </button>
//                 ))}
//
//                 <button
//                     onClick={() => onPageClick(props.currentPage + 1)}
//                     className={"btn btn-sm btn-outline-secondary"}
//                     disabled={props.currentPage === props.totalPages - 1}>
//                     <i className="bi bi-chevron-right"></i>
//                 </button>
//             </div>
//         </div>
//     );
// };
//
// Pagination.propTypes = {
//     currentPage: PropTypes.number.isRequired,
//     totalPages: PropTypes.number.isRequired,
//     onPageChange: PropTypes.func.isRequired,
// };
//
// export default Pagination;
//



import React from "react";
import PropTypes from "prop-types";
import "./Pagination.scss";

interface Props {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    totalElements: number;
    itemsPerPage: number;
}

const Pagination: React.FC<Props> = (props) => {
    const getPageNumbers = (): (number | string)[] => {
        const pageNumbers: (number | string)[] = [];
        const { currentPage, totalPages } = props;

        if (totalPages <= 1) {
            return pageNumbers;
        }

        const startPage = Math.max(currentPage - 1, 1);
        const endPage = Math.min(currentPage + 1, totalPages - 1);

        pageNumbers.push(0);

        if (currentPage > 2) {
            pageNumbers.push("...");
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (endPage < totalPages - 2) {
            pageNumbers.push("...");
        }

        if (totalPages > 1 && !pageNumbers.includes(totalPages - 1)) {
            pageNumbers.push(totalPages - 1);
        }

        return pageNumbers;
    };

    const onPageClick = (page: number) => {
        if (page >= 0 && page < props.totalPages) {
            props.onPageChange(page);
        }
    };

    const getDisplayedRange = (): string => {
        const { currentPage, itemsPerPage, totalElements } = props;
        const start = currentPage * itemsPerPage + 1;
        const end = Math.min(start + itemsPerPage - 1, totalElements);
        return `${start} to ${end} of ${totalElements}`;
    };

    const pageNumbers: (number | string)[] = getPageNumbers();

    return props.totalPages <= 1 ? null : (
        <div className={"paginate mt-3"}>
            <div className={"pagination"}>
                <div className="pageInputContainer">
                    <span className="p-2">Showing {getDisplayedRange()}</span>
                </div>
            </div>

            <div className={"d-flex buttonFooter align-items-center"}>
                <button
                    className={"btn btn-sm btn-outline-secondary me-1"}
                    onClick={() => onPageClick(props.currentPage - 1)}
                    disabled={props.currentPage === 0}>
                    <i className="bi bi-chevron-left"></i>
                </button>

                {pageNumbers.map((pageNumber: number | string, index: number) => (
                    <button
                        key={index}
                        className={`btn btn-sm me-1 ${
                            typeof pageNumber === "number" && props.currentPage === pageNumber
                                ? "btn-primary"
                                : "btn-outline-primary"
                        }`}
                        onClick={() => {
                            if (typeof pageNumber === "number") {
                                onPageClick(pageNumber);
                            }
                        }}
                    >
                        {typeof pageNumber === "number" ? pageNumber + 1 : pageNumber}
                    </button>
                ))}

                <button
                    onClick={() => onPageClick(props.currentPage + 1)}
                    className={"btn btn-sm btn-outline-secondary"}
                    disabled={props.currentPage === props.totalPages - 1}>
                    <i className="bi bi-chevron-right"></i>
                </button>
            </div>
        </div>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    totalElements: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
};

export default Pagination;
