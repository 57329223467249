import {ApiContract} from "../network/dto/ApiContract";
import {AskQuestionRequest, InitiateChatRequest} from "../models/ChatModel";
import {CHAT_API_BASE_URL} from "../common/AppConstants";

const InitiateChat = (payload: InitiateChatRequest): ApiContract => {
    return {url: `${CHAT_API_BASE_URL}/dodda_chat_v2`, method: "POST", requestBody: payload}
}

const AskQuestion = (chatId: string, userId: string, brainId: string, payload: AskQuestionRequest, offset?: number): ApiContract => {
    return {
        url: `${CHAT_API_BASE_URL}/dodda_chat_server_v2/${chatId}/question`,
        method: "POST",
        requestBody: payload,
        config: {
            params: {
                user_id: userId,
                brain_id: brainId,
                offset: offset
            }
        }
    }
}

const AskQuestionNew = (chatId: string, userId: string, brainIds: string[], payload: AskQuestionRequest, offset?: number): ApiContract => {
    return {
        url: `${CHAT_API_BASE_URL}/dodda_chat/${chatId}/question`,
        method: "POST",
        requestBody: {...payload, brain_ids: brainIds},
        config: {
            params: {
                user_id: userId,
                offset: offset
            }
        }
    }
}
const ChatHistoryAPIService=(chatId?:string): ApiContract=>{
    return {url:`${CHAT_API_BASE_URL}/chat/${chatId}/dodda_chat_history`,method:'GET'}

}

const GetChatUser=(username?:string): ApiContract=>{
    return {url:`${CHAT_API_BASE_URL}/dodda_client/${username}`,method:'GET'}

}
export {InitiateChat, AskQuestion,ChatHistoryAPIService, GetChatUser, AskQuestionNew};