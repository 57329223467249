import {useState} from "react";
import {AxiosError, AxiosResponse} from "axios";
import {ApiContract, NetworkState} from "../dto/ApiContract";
import {useAxios} from "./AxiosApiClient";
import {useAuthorizationHandler} from "../../hooks/UseAuthorizationHandler";

export const useApiClient  =<T extends any> (): [T|undefined, NetworkState, AxiosError|undefined, (contract: ApiContract)=> Promise<T>, AxiosResponse|undefined, ()=> void] => {
    const [data, setData] = useState<T>();
    const [res, setRes] = useState<AxiosResponse<T>>();
    const [error, setError] = useState<AxiosError>();
    const [state, setState] = useState<NetworkState>(NetworkState.NotLoaded);
    const axiosClient = useAxios();
    const authorizationHandler = useAuthorizationHandler();

    const clearState = () => {
        setState(NetworkState.NotLoaded)
        setData(undefined)
        setRes(undefined)
    }

    const execute = (contract: ApiContract): Promise<T> => {
        return fetch(contract);
    }

    const fetch = (contract: ApiContract): Promise<T> => {
        setState(NetworkState.Loading)
        setError(undefined);
        return new Promise((resolve, reject)=> axiosClient.request<T>({...contract.config, url: contract.url, method: contract.method, data: contract.requestBody}).then(res=> {
            setState(NetworkState.Loaded)
            setRes(res)
            setData(res.data);
            resolve(res.data);
        })
            .catch((err: AxiosError)=> {
            setState(NetworkState.Failed);
            if (err.response?.status === 403) {
                if (contract.url.startsWith('/api/admin')) {
                    authorizationHandler.onError(err); // Show the "Fail" message
                } else {
                    console.warn("403 Forbidden: Access denied but suppressed for non-admin calls.");
                }
            }
            reject(err);
            
            setError(err);
        })
        );
    }

    return [data, state, error, execute, res, clearState];
}