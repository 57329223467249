import "./reset-password.scss";
import {Button} from "../../../components/button/Button";
import {NetworkState} from "../../../network/dto/ApiContract";
import {useInputHandler} from "../../../hooks/UseInputHandler";
import {PasswordResetRequestPayload, Token} from "../../../models/LoginModels";
import {useApiClient} from "../../../network/client/UseApiClient";
import {useNavigate} from "react-router-dom";
import React, {FormEvent} from "react";
import {PasswordResetRequestApiContract} from "../../../services/LoginService";
import {v4} from "uuid";
import {useToast} from "../../../components/toast/ToastManager";
import log from "../../../assets/images/logo.svg";

export const ResetPasswordRequest = () => {
    const {payload, handleChange} = useInputHandler<PasswordResetRequestPayload>({username: ''});
    const [token, loginApiState, loginError, executeLoginApi] = useApiClient<any>();
    const toastManager = useToast();
    const navigate = useNavigate();

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        executeLoginApi(PasswordResetRequestApiContract({username: payload.username})).then(res => {
            toastManager.show({id: v4(), title: "Password reset link sent on you Email address.", duration: 5000})
        }).catch(err => {
        });
    }
    return (
        <div className={"d-flex flex-column h-100 position-relative"}>
            <img src={log} style={{width: "7rem", height: "3rem", margin: "1rem"}} alt={`_logo`}/>
            <div className={`position-absolute d-flex justify-content-center align-items-center w-100 h-100`}>
                <div className={`resetPasswordRequestPanel shadow`}>
                    <h4 style={{fontWeight: '500'}} className={`text-center text-primary`}>
                        Reset Password
                    </h4>
                    <form onSubmit={onSubmit} className={`mt-3`}>
                        <div className={'mb-3'}>
                            <label htmlFor={`username`} className={`form-label`}>Username</label>
                            <input className={`form-control`} type={`text`} id={`username`} name={`username`}
                                   value={payload.username} onChange={handleChange} required
                            />
                        </div>
                        <Button type={"submit"} loading={loginApiState === NetworkState.Loading}
                                className={`btn btn-primary w-100 mb-3 mt-3`} loaderText={`Sending...`}>Send
                            Link
                        </Button>
                        <label className={"text-center w-100"}>Go back to
                            <span className={"text-primary cursor-pointer text-decoration-underline"}
                                  onClick={() => navigate("/login", {replace: true})}
                            > Login
                        </span>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
}