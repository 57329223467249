import {Form} from "react-bootstrap";
import React, {useState} from "react";
import './CustomSwitcher.scss'

export type CustomSwitcherData = {
    active?: boolean;
    activeLabel?: string;
    inactiveLabel?: string;
    onChange: () => Promise<void>;
}

const CustomSwitcher = (props: CustomSwitcherData) => {
    const [active, setActive] = useState(props.active);
    const [isLoading, setIsLoading] = useState(false);

    const handleToggle = async () => {
        if (isLoading) return;
        setIsLoading(true);

        try {
            await props.onChange();
            setActive(!active);
        } catch (error) {
            console.error("Failed to update switch state:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return <div className={'ps-5'}>
        <Form>
            <Form.Check
                className={'ps-0 d-flex align-items-center '}
                type="switch"
                label={active ? props?.activeLabel : ''}
                checked={active}
                disabled={isLoading}
                id="custom-switch"
                onClick={handleToggle}
            />

        </Form>
    </div>
}

export default CustomSwitcher;