import axios from "axios";
import {ReportRequest, ReportResponse, UserReportResponse} from "../models/Report";

const API_URL = "https://test.ml-api.dodda.ai/ml-service/sir_documents";

export const createReport = async (reportData: ReportRequest): Promise<ReportResponse> => {
    try {
        const response = await axios.post<ReportResponse>(
            API_URL + "/create/",
            reportData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error creating report:", error);
        throw error;
    }
};

export const getUserReport = async (doddaUserId: number): Promise<UserReportResponse> => {
    try {
        const response = await axios.get<UserReportResponse>(
            API_URL + `/get_user_doc/${doddaUserId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching user report", error);
        throw error;
    }
}
