import "./LogoutModal.scss";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useSessionManager} from "../../hooks/UseSessionManager";

export const LogoutModal = (props: { show: boolean, onHide: () => void }) => {

    const navigate = useNavigate();
    const {clearSession} = useSessionManager();

    return (
        <Modal
            show={props.show}
            onHide={() => {
                props.onHide()
            }}
            centered={true}
            dialogClassName={"logout-modal"}
        >
            <ModalHeader className={"border-0"} closeButton>

            </ModalHeader>
            <ModalBody className={"text-center"}>
                <h5>
                    Are you sure you want to logout ?
                </h5>
            </ModalBody>
            <ModalFooter className={"border-0"}>
                <Button
                    size={"sm"}
                    className={"text-white px-3"}
                    style={{backgroundColor: "#FF4B57", border: "1px solid white"}}
                    onClick={() => {
                        navigate('/login');
                        clearSession();
                    }}
                >
                    Logout
                </Button>
                <Button
                    size={"sm"}
                    variant={"secondary"}
                    onClick={props.onHide}
                    className={"px-3"}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}