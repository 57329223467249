import {useContext, useState} from "react";
import sortOrder from "../../../assets/icons/sort_order.svg";
import Projects from "./project/Projects";
import {NetworkState} from "../../../network/dto/ApiContract";
import {Spinner} from "../../../components/spinner/Spinner";
import "./managePage.styles.scss";
import {useNavigate} from "react-router-dom";
import {ProjectContext} from "../../../context/ProjectContext";
import {Form} from "react-bootstrap";
import {useSessionManager} from "../../../hooks/UseSessionManager";
import {UserRole} from "../../../models/UsersModel";


export const McManagePage = () => {
    const [viewType, setViewType] = useState<'GRID' | 'LIST'>("LIST");
    // const [projects, projectNetworkState, error, fetchProjects] = useApiClient<Project[]>();
    const navigate = useNavigate();
    const {projects, projectNetworkState} = useContext(ProjectContext);
    const [showSearchIcon, setShowSearchIcon] = useState<boolean>(true);
    const {chatUser} = useSessionManager();

    return <div className={'h-100 managePage'}>
        <main className={`py-4 px-5`}>
            <div className={`d-flex justify-content-between align-items-center`}>
                <h4 className={`fw-semibold`}>Projects</h4>
                <div className={"d-flex align-items-center gap-2"}>
                    {
                        !(chatUser?.role === UserRole.Member) &&
                        <button
                            className={`btn btn-primary`}
                            onClick={() => navigate(`project/addProject`)}
                        >
                            + Add Project
                        </button>
                    }
                    <i
                        style={{border: "1px solid gray", borderRadius: "5px"}}
                        className={`${viewType === "GRID" ? "bi bi-list" : "bi bi-grid"} cursor-pointer fs-4 px-2`}
                        onClick={() => {
                            setViewType(viewType === "GRID" ? "LIST" : "GRID");
                        }}
                    ></i>
                </div>
            </div>
            {projectNetworkState === NetworkState.Loading ?
                <div className={`d-flex justify-content-center align-items-center w-100`}>
                    <Spinner/>
                </div> :
                [
                    <div className={`d-flex justify-content-between mt-2`}>
                        {/*<div className={"d-flex gap-3"}>*/}
                        {/*    <div className={"position-relative"}>*/}
                        {/*        <Form.Control*/}
                        {/*            style={{*/}
                        {/*                width: "300px",*/}
                        {/*                borderRadius: "7px",*/}
                        {/*                paddingRight: "15px",*/}
                        {/*                border: "1px solid rgba(22, 61, 118, 0.2)"*/}
                        {/*            }}*/}
                        {/*            type={"search"}*/}
                        {/*            placeholder={"Search here ..."}*/}
                        {/*            onChange={event => {*/}
                        {/*                if (event.target.value !== "") {*/}
                        {/*                    setShowSearchIcon(false);*/}
                        {/*                } else {*/}
                        {/*                    setShowSearchIcon(true);*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*        {*/}
                        {/*            showSearchIcon &&*/}
                        {/*            <i*/}
                        {/*                className={"bi bi-search position-absolute"}*/}
                        {/*                style={{right: "10px", top: "50%", transform: "translateY(-50%)"}}*/}
                        {/*            >*/}
                        {/*            </i>*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*    <div className={`ms-3 d-flex align-items-center sort`}*/}
                        {/*         style={{pointerEvents: 'none', opacity: 0.5}}>*/}
                        {/*        <img src={sortOrder} className={'me-2'}/>*/}
                        {/*        <small className={''}>Sort by: Most recent</small>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>,
                    <div className={'mt-4'}>
                        <Projects viewType={viewType} projects={projects || []}/>
                    </div>
                ]
            }
        </main>
    </div>
}