import {matchPath, Route, Routes, useLocation, useParams} from "react-router";
import "./projectDetailPage.modules.scss";
import {useNavigate} from "react-router-dom";
import backArrow from "../../../../../assets/icons/back_arrow.svg";
import {TabMenuItem} from "../../../../../components/tab/TabMenuItem";
import {ProjectDocuments} from "./projectDocuments/ProjectDocuments";
import {ProjectClients} from "./projectClients/ProjectClients";
import {useContext, useEffect} from "react";
import {useApiClient} from "../../../../../network/client/UseApiClient";
import {GetSingleProject} from "../../../../../services/ProjectService";
import {DefaultProjectContext} from "../../../../../hooks/DefaultProject";
import {Project} from "../../../../../models/Project";
import {ProjectPartners} from "./projectPartners/ProjectPartners";
import {ProjectTeamMembers} from "./projectTeam/ProjectTeamMembers";
import {useToast} from "../../../../../components/toast/ToastManager";
import {v4} from "uuid";

export const ProjectDetailPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams<{projectId: string}>();
    const dps = useContext(DefaultProjectContext);
    const toast = useToast();
    const [project, projectNetworkState, error, fetchSingleProject, _, clearState] = useApiClient<Project>();


    useEffect(()=> {
        clearState()
       fetchSingleProject(GetSingleProject(params?.projectId || '')).then(res=> {
           if (dps.setProject)
                dps.setProject(res)
       }).catch(err => {
           toast.show({id: v4(), title: err?.response?.data?.message, isSuccess: false, duration: 2000})
       })
       return ()=> {
           if (dps.setProject)
                dps.setProject(undefined)
       }
    },[params.projectId])


    return <div className={`projectDetailPage`}>
        <div className={`d-flex justify-content-evenly`}>
            <div className={`fw-bold col`}>
                <div className={"d-inline-flex align-items-center cursor-pointer"} onClick={()=> {navigate('/manage')}}>
                    <img src={backArrow} className={`me-1 backArrow`}/>
                    <small>Back to projects</small>
                </div>
            </div>
            <div className={`col text-center fw-bold`} key={params.projectId}>{project?.name}</div>
            <div className={`col`}></div>
        </div>

        <div className={` d-flex justify-content-center mt-5`}>
            <nav className="nav flex-column flex-sm-row nav-tabs my-3">
                <TabMenuItem label={'Documents'} pathname={`documents`}
                             isActive={!!matchPath("/manage/project/:projectId/documents/*", location.pathname)}/>
                <TabMenuItem label={'Owners'} pathname={`owners`}
                             isActive={!!matchPath("/manage/project/:projectId/clients/*", location.pathname)}/>
                <TabMenuItem label={'Partners'} pathname={`partners`}
                             isActive={!!matchPath("/manage/project/:projectId/partners/*", location.pathname)}/>
                <TabMenuItem label={'Team'} pathname={`team`}
                             isActive={!!matchPath("/manage/project/:projectId/team/*", location.pathname)}/>
                {/*<TabMenuItem label={'Integrations'} pathname={`integrations`}*/}
                {/*             isActive={!!matchPath("/manage/project/:projectId/settings*", location.pathname)}/>*/}
            </nav>
        </div>

        <div>
            <Routes key={params.projectId}>
                <Route path={'documents'} element={<ProjectDocuments/>} />
                <Route path={'owners'} element={<ProjectClients/>} />
                <Route path={'partners'} element={<ProjectPartners/>} />
                <Route path={'team'} element={<ProjectTeamMembers/>} />
                {/*<Route path={'integrations'} element={<Integrations/>} />*/}
                {/*<Route path={'clients/:id'} element={<ClientDetailPage/>} />*/}
            </Routes>
        </div>
    </div>;
}