export const McDashboardPage = () => {
    return (
        <div className="d-flex justify-content-center align-items-center p-5 w-100 h-100">
            <iframe
                src="https://68e5-34-123-15-205.ngrok-free.app/"
                width="100%"
                height="100%"
                style={{border: 'none'}}
                allowFullScreen
            ></iframe>
        </div>
    )
}