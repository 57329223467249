import {useInputHandler} from "../../hooks/UseInputHandler";
import {LoginRequestPayload, Token} from "../../models/LoginModels";
import React, {FormEvent, useEffect, useState} from "react";
import {useApiClient} from "../../network/client/UseApiClient";
import {NetworkState} from "../../network/dto/ApiContract";
import {useSessionManager} from "../../hooks/UseSessionManager";
import './login.scss';
import {Link, useNavigate} from "react-router-dom";
import {useToast} from "../../hooks/UseToast";
import {v4} from "uuid";
import {Button} from "../../components/button/Button";
import {LoginApiContract} from "../../services/LoginService";
import {tokenToString} from "typescript";
import {jwtDecode, JwtPayload as JwtDecodePayload} from 'jwt-decode';
import {Form} from "react-bootstrap";
import log from "../../assets/images/logo.svg";
import {useLocation} from "react-router";


export const LoginPage = () => {
    const {payload, handleChange} = useInputHandler<LoginRequestPayload>({username: '', password: ''});
    const [token, loginApiState, loginError, executeLoginApi] = useApiClient<Token>();
    const [chatUser, chatUserState, chatUserError, fetchChatUser] = useApiClient<{
        user_name: string,
        client: string
    }>();
    const {session, createSession, setChatUser} = useSessionManager();
    const {displayToast} = useToast();
    const navigate = useNavigate();
    const [orgId, setOrgId] = useState<number | null>(null);
    const location = useLocation();

    interface ExtendedJwtPayload extends JwtDecodePayload {
        orgId?: number;
    }


    useEffect(() => {
        console.log("Login Page useEffect triggered: Checking token state...");
        console.log(tokenToString)
        if (token && createSession && loginApiState === NetworkState.Loaded && chatUserState === NetworkState.NotLoaded) {
            console.log("Token received: ", token);
            createSession(token.access_token || '', {id: token.id, username: token.username});
            displayToast({id: v4(), label: 'Login Success', message: `${token.username} logged in successfully.`})

            const decodedToken = jwtDecode<ExtendedJwtPayload>(token.access_token); // Pass the JWT string here

            console.log("Decoded", decodedToken);
            console.log("New Organization ID (newOrgId): ", decodedToken.orgId);


            if (decodedToken.orgId !== undefined) {
                setOrgId(decodedToken.orgId); // Only set if orgId is defined
            } else {
                console.log("No orgId in the token");
            }
            if (location.state && location.state.approval) {
                navigate("/settings/approval");
            } else {
                navigate('/manage');
            }
        } else {
            console.log("Error in login: ", loginError);
            displayToast({id: v4(), label: 'Login Error', message: ``})
        }
    }, [token, createSession]);

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        console.log("Login form submitted: ", payload);

        if (!payload.username || !payload.password) {
            console.log("Error: Missing username or password in the payload.");
            return;
        }

        console.log("Executing login API with data: ", LoginApiContract({
            username: payload.username,
            password: payload.password
        }));


        executeLoginApi(LoginApiContract({username: payload.username, password: payload.password})).then(() => {
        }).catch(err => {

        });
    }

    return (
        <div className={"d-flex flex-column h-100 position-relative"}>
            <img src={log} style={{width: "7rem", height: "3rem", margin: "1rem"}} alt={`_logo`}/>
            <div className={`position-absolute d-flex justify-content-center align-items-center w-100 h-100`}>
                <div className={`loginPanel shadow`}>
                    <h4 style={{fontWeight: '500'}} className={`text-center text-primary`}>Login to dodda.ai</h4>
                    {loginError ? <div className={`text-danger mb-2`}>Invalid Username/Password</div> : null}
                    <form onSubmit={onSubmit} className={`mt-4`}>
                        <div className={'mb-3'}>
                            <label htmlFor={`username`} className={`form-label`}>Username</label>
                            <input className={`form-control`} type={`text`} id={`username`} name={`username`}
                                   value={payload.username} onChange={handleChange} required/>
                        </div>
                        <div className={'mb-3 mt-4'}>
                            <div className={"d-flex justify-content-between align-items-baseline"}>
                                <label htmlFor={`password`} className={`form-label`}>Password</label>
                                <label className={"text-primary cursor-pointer"} style={{fontSize: "0.8rem"}}
                                       onClick={() => navigate("/reset-password", {replace: true})}>Forgot
                                    Password?</label>
                            </div>
                            <input className={`form-control`} type={`password`} id={`password`} name={`password`}
                                   value={payload.password} onChange={handleChange} required/>
                        </div>
                        <Button type={"submit"}
                                loading={loginApiState === NetworkState.Loading}
                                className={`btn btn-primary w-100`}
                        >
                            Login
                        </Button>
                        <label className={"text-center w-100 mt-4"}>Don't have an account?
                            <span className={"text-primary cursor-pointer"}
                                  onClick={() => navigate("/register")}
                            > Register
                        </span>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
}