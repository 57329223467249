import "./chatroom.styles.scss";
import bot from "../../assets/icons/bot.svg";
import send from "../../assets/icons/send.svg";
import {useApiClient} from "../../network/client/UseApiClient";
import {useInputHandler} from "../../hooks/UseInputHandler";
import React, {FormEvent, Fragment, useContext, useEffect, useRef, useState} from "react";
import {AskQuestion, AskQuestionNew, ChatHistoryAPIService} from "../../services/ChatService";
import {v4} from 'uuid';
import {
    AskQuestionRequest,
    ChatInitiationResponse,
    QuestionResponse,
    ReferenceDoc,
    TokenLimitStatus
} from "../../models/ChatModel";
import {NetworkState} from "../../network/dto/ApiContract";
import {useToast} from "../../components/toast/ToastManager";
import {ChatBubbleLet} from "./ChatBubbleLet";
import {ChatBubbleRight} from "./ChatBubbleRight";
import typing from '../../assets/icons/Animation - typing.gif';
import {useSessionManager} from "../../hooks/UseSessionManager";
import {ChatScreenShimmer} from "../../shimmer/chatScreenShimmer/ChatScreenShimmer";
import {ChatInfoMessage} from "./ChatInfoMessage";
import {DefaultProjectContext} from "../../hooks/DefaultProject";
import {PdfViewerDialog} from "../../components/pdf/PdfViewerDialog";
import {ServerFile} from "../../models/Document";
import {DrawingsStrip} from "./drawing/DrawingsStrip";
import {DrawingDialog} from "../../components/drawing/DrawingDialog";
import {Drawing} from "../../models/Drawing";
import { logEvent } from "../../utils/ganalytics";

export const Chatroom = (props: {chatId: string, brainId: string[], onNewChat?: ()=> void, onHide:()=> void}) => {
    const [chatRes, chatState, chatError, sendMessage] = useApiClient<QuestionResponse>();
    const [refRes, refState, refError, loadMoreRef] = useApiClient<QuestionResponse>();
    const [chatHistory, chatHistoryState, chatHistoryError, chatHistoryMessage] = useApiClient<ChatInitiationResponse>();
    const {payload, handleChange, setValue} = useInputHandler<{question?: string, model: string, temperature: number, max_tokens: number}>({max_tokens: 250, model: 'gpt-3.5-turbo-0613', temperature: 0});
    const [messages, setMessages] = useState<{id: string, type: 'Bot'|'Client'|'User'|'Info', content: string, time?: string, referenceDocs: ReferenceDoc[], drawings: Drawing[]}[]>([]);
    const toastManager = useToast();
    const {chatUser, chatTimestamp} = useSessionManager();
    const [showPdfViewer, setShowPdfViewer] = useState<ServerFile>();
    const [limitExceeded, setLimitExceeded] = useState<boolean>(false);
    const messageForm = useRef<HTMLFormElement>(null);
    const toast = useToast();
    const dps = useContext(DefaultProjectContext);
    const [showDrawing, setShowDrawing] = useState<Drawing>();
    const [documentIds, setDocumentIds] = useState<string[]>([]);


    useEffect(() => {
        if (chatState === NetworkState.Loaded && chatRes) {
            if (chatRes.token_limit === TokenLimitStatus.EXCEEDED && !limitExceeded) {
                setLimitExceeded(chatRes.token_limit === TokenLimitStatus.EXCEEDED)
                toast.show({id: v4(), title: "Chat limit exceeded!",isSuccess:false,duration:5000})
                pushMessages([{id: v4(), type: 'Client', content: 'Can you summarize the discussion?'}]);
                sendMessage(AskQuestionNew(props.chatId, chatUser?.quivrId||'belfour_beatty', props.brainId||'', {question: 'Can you summarize the discussion?', model: 'gpt-3.5-turbo-0613', max_tokens: 250, temperature: 0} as AskQuestionRequest)).then(res=> {
                    pushMessages([{id: v4(), type: 'Bot', content: res.assistant}, {id: v4(), type: 'Info', content: 'Chat limit has exceeded please start a New Chat!'}]);
                    setValue('question', '');
                }).catch(err=> {
                    if (err.response.status !== 403)
                        console.log('err',err.response)
                        toastManager.show({id: v4(), title: err.response.data.message, isSuccess: false, duration: 5000});
                });
            }
        }
    }, [chatState]);

    const askQuestion = (event: FormEvent) => {
        event.preventDefault();
        if ((payload.question?.length || 0) > 0) {
            pushMessages([{id: v4(), type: 'Client', content: payload.question||''}]);
            console.log("GA Metric for User Chat Question");
            logEvent("User Chat", "Question", "Chat");
            sendMessage(AskQuestionNew(props.chatId, chatUser?.quivrId||'belfour_beatty', props.brainId||'', payload as AskQuestionRequest)).then(res=> {
                pushMessages([{id: v4(), type: 'Bot', content: res.assistant, referenceDocs: res.context_docs, drawings: res.context_docs}]);
                setValue('question', '');
            }).catch(err=> {
                if (err.response.status !== 403)
                    console.log('data',err.response.data.status)
                    console.log('err',err.response)
                    toastManager.show({id: v4(), title: err.response.data.message, isSuccess: false, duration: 5000});
            });
        }
    }

    const loadMoreReferences = (messageId: string) => {
        const messageIdx = messages.findIndex(value => value.id === messageId);
        let searchQuery = "";
        if (messageIdx > -1) {
            searchQuery = messages[messageIdx-1].content
        }
        if (searchQuery.trim() !== '') {
            const req = {...payload, query: searchQuery};
            loadMoreRef(AskQuestionNew(props.chatId, chatUser?.quivrId||'belfour_beatty', props.brainId||'', req as AskQuestionRequest, messages[messageIdx].drawings.length)).then(res=> {
                updateMessage(messageIdx, res.context_docs);
                setValue('question', '');
            }).catch(err=> {
                if (err.response.status !== 403)
                    console.log('data',err.response.data.status)
                console.log('err',err.response)
                toastManager.show({id: v4(), title: err.response.data.message, isSuccess: false, duration: 5000});
            });
        }
    }

    const onEnterPress = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if(evt.keyCode == 13 && evt.shiftKey === false) {
            evt.preventDefault();
            if (typeof messageForm.current?.requestSubmit === 'function') {
                messageForm.current.requestSubmit();
            } else {
                messageForm.current?.dispatchEvent(new Event('submit', {cancelable: true}));
            }
        }
    }

    // const chatHistoryData =()=> {
    //     chatHistoryMessage(ChatHistoryAPIService(props.chatId )).then((res:any)=> {
    //         const _messages: {id: string, type: 'Bot'|'Client'|'User', content: string, time?: string, referenceDocs: ReferenceDoc[], drawings: Drawing[]}[] = [];
    //         (res).map((message: any)=> {
    //             _messages.push({id: v4(), type: 'Client', content: message.user_message, time: message.message_time, referenceDocs: [], drawings: []});
    //             _messages.push({id: v4(),type: 'Bot', content: message.assistant, time: message.message_time, referenceDocs: res.context_docs, drawings: res.context_docs})
    //         });
    //         setMessages((prevMessages)=> ([..._messages]))
    //     })
    // }

    // useEffect(() => {
    //     chatHistoryData();
    // }, []);

    const pushMessages = (message: any[]) => {
        setMessages((prevState)=> {
            const _messages = [...prevState];
            _messages.push(...message);
            return _messages;
        });
    }

    const updateMessage = (idx: number, drawings: any[]) => {
        const _msgs = [...messages];
        _msgs[idx].drawings = [..._msgs[idx].drawings, ...drawings];
        setMessages(_msgs);
    }

    return <div className={`chatroom border w-100`}>
        <div className={`d-flex h-100`}>
            <div className={`sidebar h-100`}>
                <div className={`p-4`}>
                    <button className={`btn btn-primary w-100`} onClick={() => {
                        if (props.onNewChat && window.confirm("Are you sure, want to start new Conversation?")) {
                            props.onNewChat();
                        } else {

                        }
                    }}><span className={`bi bi-plus`}/> New Chat</button>
                </div>

                <div className={`flex-fill p-4`}>
                    {/*{*/}
                    {/*    chatTimestamp?[<label className={`small text-secondary`}>Recent Chat</label>,*/}
                    {/*        <div className={`py-1 px-2 mt-2 cursor-pointer active chatEntry`}>*/}
                    {/*            <span className={`bi bi-chat-left me-2 text-secondary`} />*/}
                    {/*            <label className={`fw-light`}>{chatTimestamp?.format('DD MMM | hh:mm')}</label>*/}
                    {/*        </div>]:null*/}
                    {/*}*/}
                </div>
                <div className={`d-flex flex-column justify-content-start align-self-start border-top p-3 w-100`}>
                    {/*<button className={`btn text-secondary text-start py-1 mb-1`}><span className={`bi bi-person-add me-2 fs-4`}/> Add participants to this chat</button>*/}
                    {/*<button className={`btn bg-light border-light text-white  text-start py-1 align-self-start`} disabled><span*/}
                    {/*    className={`bi bi-envelope me-2 fs-4`}/> Email chat summary*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className={`flex-fill d-flex flex-column h-100 overflow-hidden`}>
                <div className={`header`}>
                    <div className={`d-flex justify-content-start col text-secondary align-items-center`}>
                        {/*<img src={calenderTime} className={``} />*/}
                    </div>
                    <div className={`col d-flex justify-content-center align-items-center title`}>
                        <img src={bot} className={``}/> <span className={`heading`}>AI</span> <span className={`ms-2 text-decoration-underline text-dark`}>{dps.activeBrains.length} Project(s)</span>
                    </div>
                    <div className={`col d-flex justify-content-end align-items-center`}>
                        {/*<img src={videoCall} className={`me-3`} />*/}
                        <span className={`bi bi-x-lg text-black cursor-pointer`} onClick={()=> props.onHide()}/>
                    </div>
                </div>
                <div className={`content`}>
                    {chatState === NetworkState.Loading ?
                        <small className={'text-secondary'}>Thinking<img src={typing} style={{height: '40px'}}
                                                                         className={'text-center mt-2'}/></small> : null}
                    {chatHistoryState === NetworkState.Loading ? [<ChatScreenShimmer/>, <ChatScreenShimmer/>] :
                        <Fragment>
                            {[...messages].reverse().map((message, idx) => <Fragment>
                                {message.drawings && message.drawings.length > 0? <DrawingsStrip showLoader={refState === NetworkState.Loading} drawings={message.drawings} onShowDrawing={setShowDrawing} onShowDoc={setShowPdfViewer} onLoadMore={()=> loadMoreReferences(message.id)} />:null}
                                {(message.type === 'Info') ?

                                <ChatInfoMessage children={message.content}/> : (message.type === 'Bot') ?
                                    <ChatBubbleLet key={idx} time={message.time}
                                                   onShowDocument={(document) => setShowPdfViewer(document)}>{message.content}</ChatBubbleLet> :
                                <ChatBubbleRight key={idx}>{message.content}</ChatBubbleRight>}

                            </Fragment>)}

                        </Fragment>
                    }
                </div>
                <div className={`footer`}>
                    <form ref={messageForm} className={`d-flex justify-content-between`} onSubmit={askQuestion}>
                        <textarea disabled={limitExceeded} rows={1} onKeyDown={onEnterPress}
                                  className={`flex-fill messageBox`} name={`question`} value={payload.question}
                                  onChange={handleChange} placeholder={`Ask me anything...`}/>

                        {/*<span className={`bi bi-camera fs-4 me-3 ms-2 align-self-center`}/>*/}
                        <button type={`submit`} className={`btn btn-accent`} disabled={limitExceeded}>Send <img
                            src={send} className={`ms-1`}/></button>
                    </form>
                </div>
            </div>
        </div>
        <PdfViewerDialog document={showPdfViewer} onHide={() => setShowPdfViewer(undefined)}/>
        <DrawingDialog onHide={()=> {
            setShowDrawing(undefined)
        }} data={showDrawing} drawing={showDrawing} />
    </div>;
}