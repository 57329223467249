import "./ProjectSidebar.scss";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ProjectContext} from "../../../../context/ProjectContext";
import {DefaultProjectContext} from "../../../../hooks/DefaultProject";
import {Accordion} from "react-bootstrap";
import {useAppContext} from "../../../../context/AppContext";
import Joyride, {Step} from "react-joyride";


export const ProjectSidebar = () => {
    const {projects, activeProjectId, setActiveProject} = useContext(ProjectContext);
    const navigate = useNavigate();
    const {activeBrains, setActiveBrains} = useContext(DefaultProjectContext);
    const [activeKey, setActiveKey] = useState<string>('project_list')
    const {showChatBubble, onProjectSelection} = useAppContext();
    const [runTour, setRunTour] = useState<boolean>(false);

    useEffect(() => {
        if (showChatBubble) {
            setRunTour(true);
        } else {
            setRunTour(false);
        }
    }, [showChatBubble]);

    const steps: Step[] = [
        {
            target: "#project-accordion-item",
            content: "Click here to select a project",
            placement: "top",
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
            disableScrolling: true
        }
    ];

    const handleJoyrideCallback = (data: any) => {
        const { status } = data;
        if (["finished", "skipped"].includes(status)) {
            setRunTour(false);
            onProjectSelection();
        }
    };

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        let brainId = evt.target.value;
        const selectedBrains = [...activeBrains];
        const idx = selectedBrains.findIndex(itm => itm === brainId);
        if (idx > -1) {
            selectedBrains.splice(idx, 1);
        } else {
            selectedBrains.push(brainId);
        }
        if (setActiveBrains){
            setActiveBrains(selectedBrains);
            onProjectSelection();
        }
    }

    const isProject = (brainId: string | undefined): boolean => {
        return !!brainId;
    }
    const handleOpen = (key: string) => {
        setActiveKey(key);
    };

    const handleClose = () => {
        setActiveKey('');
    };
    return (
        <div className={`w-100 h-100 p-3`}>
            <h5 className={"fw-semibold"}><i className="bi bi-pencil-square me-3"/>Search Scope</h5>
            <hr className={`mt-1`}/>
            <Accordion activeKey={activeKey}>
                <div className={`d-flex`}>
                    <div className={``}>
                        <input className="form-check-input me-2 border-black" type="checkbox" value="" id="flexCheckDefault"
                               onChange={(evt) => {
                                   const _projects: (string)[] = projects.map(itm => itm.brainId).filter(isProject) as string[];
                                   if (setActiveBrains) {
                                       onProjectSelection();
                                       evt.target.checked ? setActiveBrains(_projects) : setActiveBrains([]);
                                   }
                               }} checked={activeBrains.length === projects.length}/>
                    </div>
                    <Accordion.Item
                        id={"project-accordion-item"}
                        eventKey={'project_list'}
                        className={"flex-fill border-0 w-100 d-flex justify-content-between align-items-baseline cursor-pointer"}
                        onClick={() => activeKey ? handleClose() : handleOpen('project_list')}
                    >
                        <h6 className={`fw-semibold`}>Select a project</h6>
                        <div className={`fw-bold bi ${activeKey ? 'bi-chevron-down' : 'bi-chevron-right'}`}/>
                    </Accordion.Item>
                </div>
                <Accordion.Collapse eventKey={'project_list'}>
                    <div className={`ps-4 pe-3 pt-2 rounded mt-1`}>
                        {projects?.map(project => (<div className="form-check">
                            <input className="form-check-input border-black" type="checkbox" name={`project_ids`}
                                   value={project.brainId}
                                   checked={activeBrains.findIndex(itm => itm === project.brainId) > -1}
                                   id={project.id} onChange={handleChange}/>
                            <label className="fw-semibold" htmlFor={project.id}>
                                <div className={"project_name"}>
                                    {project.name}
                                </div>
                            </label>
                        </div>))}
                    </div>
                </Accordion.Collapse>
            </Accordion>
            <Joyride
                steps={steps}
                run={runTour}
                continuous={true}
                showSkipButton={true}
                callback={handleJoyrideCallback}
                styles={{
                    options: {
                        zIndex: 1000,
                        backgroundColor: "#333", // Change background color
                        textColor: "#fff", // Change text color
                        primaryColor: "#ffcc00", // Change the highlight color
                        arrowColor: "#333", // Change the arrow color
                    },
                    tooltip: {
                        backgroundColor: "#333", // Tooltip background color
                        color: "#fff", // Text color inside tooltip
                        fontSize: "14px",
                        width: "max-content",
                        height: "max-content",
                        padding: "5px"
                    },
                    buttonClose: {
                        color: "#fff",
                        display: "none"
                    },
                    buttonNext: {
                        backgroundColor: "#ffcc00", // Customize Next button
                        color: "#000",
                    },
                    buttonBack: {
                        color: "#ffcc00", // Customize Back button
                    },
                }}
            />
        </div>
    );
}