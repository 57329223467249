import './adminSettings.scss'
import React, {useEffect, useState} from "react";
import {TabMenuItem} from "../../components/tab/TabMenuItem";
import {matchPath, Route, Routes, useLocation} from "react-router";
import Users from "../manageUsers/Users";
import {Integrations} from "../management-console/manage/project/details/projectSettings/Integrations";
import BrandSetting from "./brandsetting/BrandSetting";
import GeneralSetting from "./general/General";
import AdminPortal from "../../components/adminPortal/AdminPortal";
import {useApiClient} from "../../network/client/UseApiClient";
import {UserRole, UsersModel} from "../../models/UsersModel";
import {AccountDetailApi} from "../../services/AccountService";


export const AdminSettings =()=>{
    const path = useLocation();
    const [activeMenu, setActiveMenu] = useState<string>("");
    const [user, userNetworkState, error, userData] = useApiClient<UsersModel>();
    const [orgId, setOrgId] = useState<number>(0);
    const [userRole, setUserRole] = useState<string | undefined>(undefined);

    useEffect(() => {
        userData(AccountDetailApi()).then(res => {
            setOrgId(parseInt(res?.organization?.id ?? "0"));
            setUserRole(res?.role)
        })
    }, []);

    useEffect(()=> {
        if (path) {
            setActiveMenu(path.pathname)
        }
    }, [path])


    return <div className={'px-5 py-4 h-100 managePage'}>

                <h4 className={`fw-bold`}>Admin Settings</h4>

               <div className={'d-flex position-relative justify-content-start z-1 ms-0'}>
                   <nav className="nav flex-column flex-sm-row nav-tabs my-3">
                       <TabMenuItem  label={'Manage Users'} pathname={'users'}
                                    isActive={!!matchPath("/settings/users/*", path.pathname)}/>
                       <TabMenuItem label={'Sync Settings'} pathname={'sync'}
                                    isActive={!!matchPath("/settings/sync/*", path.pathname)}/>
                       <TabMenuItem  label={'Brand Settings'} pathname={'brand'}
                                    isActive={!!matchPath("/settings/brand/*", path.pathname)}/>
                       <TabMenuItem label={'General'} pathname={'general'}
                                    isActive={!!matchPath("/settings/general/*", path.pathname)}/>
                       {
                           orgId === 4 && userRole === UserRole.SuperAdmin &&
                           <TabMenuItem label={'Org Approvals'} pathname={'approval'}
                                        isActive={!!matchPath("/settings/approval/*", path.pathname)}/>
                       }
                   </nav>
               </div>
        <div className={`flex-fill bg-body`}>
            <Routes>
                <Route path={'/users'} element={<Users/>}/>
                <Route path={'/sync'} element={<Integrations/>}/>
                <Route path={'/brand'} element={<BrandSetting/>}/>
                <Route path={'/general'} element={<GeneralSetting/>}/>
                <Route path={'/approval'} element={<AdminPortal/>}/>
            </Routes>
        </div>
    </div>
}
export default AdminSettings;