import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import log from "../../assets/images/logo.svg";
import { useApiClient } from "../../network/client/UseApiClient";
import { verifyEmailApiContract } from "../../services/VerifyEmailService";
import {Spinner} from "react-bootstrap";

const VerifyEmailPage = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [verifyResponse, verifyApiState, verifyError, executeVerifyApi] = useApiClient<string>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get('token');

        if (!token) {
            setMessage('Invalid verification link.');
            return;
        }

        console.log("Sending verification request for token:", token);

        const verifyEmail = async () => {
            try {
                const response = await executeVerifyApi(verifyEmailApiContract(token));
                console.log("Verification response:", response);
                
                if (response?.includes("successful")) {
                    setMessage("Email verified successfully!");
                } else {
                    setMessage("Verification failed.");
                }
            } catch (error) {
                console.error("Verification error:", error);
                setMessage("An error occurred. Please try again.");
            } finally {
                setLoading(false);
            }
        };
        verifyEmail();
        }, []);


    const getIcon = () => {
        if (message === 'Email verified successfully!') {
            return <img src="https://img.icons8.com/color/96/checked--v1.png" alt="Success" width="60" />;
        } else if (message === 'Invalid verification link.' || message === 'Verification failed.') {
            return <img src="https://img.icons8.com/fluency/96/close-window.png" alt="Failure" width="60" />;
        }
        return null;
    };

    return !loading ? (
        <div className={"d-flex flex-column h-100 position-relative"}>
            <img src={log} style={{width: "7rem", height: "3rem", margin: "1rem"}} alt={`_logo`}/>
            <div className={`position-absolute d-flex justify-content-center align-items-center w-100 h-100`}>
                <div
                    className="d-flex flex-column justify-content-center align-items-center vh-100 text-center p-4">
                    {getIcon()}
                    <h2 className="fw-bold mt-4">
                        {message === 'Email verified successfully!' ? 'Email Verified' : 'Verification Failed'}
                    </h2>
                    <p className={`text-muted mt-3 ${message === 'Email verified successfully!' ? 'text-success' : 'text-danger'}`}>
                        {message === 'Email verified successfully!'
                            ? "Your email has been successfully verified. Once admin approves your request, we'll email you."
                            : (
                                <>
                                    There was an issue with the verification link. Try contacting the admin{' '}
                                    <a className="text-primary cursor-pointer"
                                       href={process.env.REACT_APP_MARKETING_URL ?? ""}>
                                        here
                                    </a>.
                                </>
                            )}
                    </p>
                </div>
            </div>
        </div>
    ) :
        <div className={"d-flex justify-content-center align-items-center w-100 vh-100"}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
            </Spinner>
        </div>
};

export default VerifyEmailPage;