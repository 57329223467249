import {KEY_ACCESS_TOKEN, useSessionManager} from "../../hooks/UseSessionManager";
import {matchPath, Navigate, useLocation} from "react-router";
import {useEffect, useState} from "react";

export const AuthGuard = (props: {element: any, to: string}) => {

    const location = useLocation();
    const [isLoggedIn, setLoggedIn] = useState<boolean>(!!localStorage.getItem(KEY_ACCESS_TOKEN));


    return isLoggedIn? props.element: matchPath(location.pathname, "/settings/approval") ? <Navigate to={"/login"} state={{approval: true}}/> : <Navigate to={props.to} key={props.element.key} />
}