import {AxiosError} from "axios";
import {useToast} from "../components/toast/ToastManager";
import {v4} from "uuid";

export const useAuthorizationHandler = () => {
    const toastManager = useToast();

    const onError = (error: AxiosError)=> {
        if (error) {
            const message = (error.response?.data as any).message;
            toastManager.show({id: v4(), title: `You don't have permission to perform this operation.`, duration: 4000, isSuccess: false});
        }
    }
    return {onError};
};