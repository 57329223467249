import {createContext, ReactNode, useContext, useState} from "react";


interface ContextValue {
    showChatBubble: boolean;
    onChatIconClick: () => void;
    onProjectSelection: () => void;
}

const AppContext = createContext<ContextValue | undefined>(undefined);

export const AppProvider = (props: {children: ReactNode}) => {
    const [showChatBubble, setShowChatBubble] = useState<boolean>(false);

    const onChatIconClick = () => {
        setShowChatBubble(true);
    }

    const onProjectSelection = () => {
        setShowChatBubble(false);
    }

    return(
        <AppContext.Provider value={{showChatBubble, onChatIconClick, onProjectSelection}}>
            {props.children}
        </AppContext.Provider>
    )
}

export const useAppContext = (): ContextValue => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppProvider");
    }
    return context;
};