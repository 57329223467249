import { ApiContract } from "../network/dto/ApiContract";

// 🔹 Define API contract for email verification
const verifyEmailApiContract = (token: string): ApiContract => {
    return {
        url: `api/public/registration/verify?token=${token}`, 
        // url: `http://localhost:8081/api/public/registration/verify?token=${token}`, // Use this for local testing
        method: "GET",
        requestBody: null
    };
};

export { verifyEmailApiContract };
